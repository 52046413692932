import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import axios from 'model/axios'
import Select from 'components/Input/Select'
import { Title, Text } from './NotificationItem.styles'
import { Modal, ModalInner } from 'components/Modal/Modal'
import {
  Button,
  ComponentWrapper,
  Row,
  Column,
} from '@stokr/components-library'
import fetchData from 'api/fetch-data'

class UserChannelModal extends PureComponent {
  state = {
    selectedChannel: '',
    successMsg: false,
  }

  filterValue = (e) => {
    this.setState({
      selectedChannel: e.value,
    })
  }

  submitChannelValue = async (e) => {
    const accessToken = await Cookies.get('STOKR_ACCESS_TOKEN')
    const data = {
      source: this.state.selectedChannel,
    }
    try {
      const result = await axios({
        method: 'POST',
        url: `/incoming-user-channel/create`,
        data,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      if (result && result.status === 200) {
        this.setState({
          successMsg: true,
        })
      }
    } catch (error) {
      console.log(`Error: ${error}`)
    }
  }

  render() {
    const {
      isModalOpen,
      onModalClose,
      onFormSend,
      onModalSwitch,
      popupError,
      popupSuccess,
      isActionLoading,
    } = this.props

    const { selectedChannel, successMsg } = this.state

    return (
      <Modal isOpen={isModalOpen} onClose={onModalClose} isSuccess={successMsg}>
        <Row>
          <Column part={8}>
            <ModalInner modalTop>
              <Text>
                <h3
                  style={{
                    fontFamily: 'Open Sans',
                    fontWeight: '800',
                    fontSize: '34px',
                    lineHeight: '1.27em',
                    letterSpacing: '0.8px',
                    textTransform: 'uppercase',
                  }}
                >
                  How did you discover STOKR?
                </h3>
                <p>
                  Please let us know from which channel you found out about
                  STOKR?
                </p>
              </Text>
            </ModalInner>
            <ModalInner modalBot></ModalInner>
          </Column>
          <Column part={8}>
            {successMsg !== true ? (
              <ModalInner>
                <Title>{'Please select the channel'}</Title>
                <Select
                  id="userChannel"
                  name={'userChannel'}
                  options={[
                    { key: 1, value: 'Search engine', label: 'Search engine' },
                    { key: 2, value: 'Word of mouth', label: 'Word of mouth' },
                    { key: 3, value: 'Twitter', label: 'Twitter' },
                    { key: 4, value: 'LinkedIn', label: 'LinkedIn' },
                    { key: 5, value: 'Youtube', label: 'YouTube' },
                    { key: 6, value: 'Instagram ad', label: 'Instagram ad' },
                    { key: 7, value: 'Facebook ad', label: 'Facebook ad' },
                    { key: 8, value: 'Email', label: 'Email' },
                    { key: 9, value: 'Publication', label: 'Publication' },
                    { key: 10, value: 'Other', label: 'Other' },
                  ]}
                  value={selectedChannel}
                  label="Select Medium"
                  onChange={this.filterValue}
                  onBlur={(e) => {}}
                />
                <ComponentWrapper noPaddingBottom noPaddingHorizontal>
                  <Button
                    type="submit"
                    fluid
                    disabled={selectedChannel === ''}
                    onClick={this.submitChannelValue}
                  >
                    Submit
                  </Button>
                </ComponentWrapper>
              </ModalInner>
            ) : (
              <ModalInner>
                <Text>
                  <br />
                  <br />
                  <p>Thank you for providing us this information.</p>
                </Text>
              </ModalInner>
            )}
          </Column>
        </Row>
      </Modal>
    )
  }
}

UserChannelModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onFormSend: PropTypes.func.isRequired,
  onModalSwitch: PropTypes.func.isRequired,
}

export default UserChannelModal
