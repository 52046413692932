import React, { Fragment, PureComponent } from 'react'
import { Collapse } from 'react-collapse'

import SettingsLayout from 'components/SettingsLayout/SettingsLayout'
import {
  Button,
  Row,
  Column,
  ComponentWrapper,
  CryptoAddressDetails,
  SectionTitle,
  CryptoAddress,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import { FlexCenterContainer } from 'components/Settings/Settings.styles'
import user from 'api/user'
import avatarImage from 'static/images/user-profile.png'
import { BigNumber } from 'bignumber.js'
import fetchData from 'api/fetch-data'
import { AlgoAddressFlowPage, AuthProvider } from '@stokr/components-library'
import { Tooltip } from 'react-tippy'
import { TooltipIcon2 } from 'components/Icon/Icon.style'

class AlgorandAdresses extends PureComponent {
  state = {
    addresses: [],
    expandedAddressId: null,
    openModal: false,
  }

  toggleAddress = (addressId) => {
    const { expandedAddressId } = this.state
    this.setState({
      expandedAddressId: addressId !== expandedAddressId ? addressId : null,
    })
  }

  removeAddress = (addressId) => {
    const { addresses } = this.state
    this.setState({
      addresses: addresses.filter((address) => address.id !== addressId),
    })
  }

  async componentDidMount() {
    // try {
    let userObj = await user('get')

    if (userObj.user) {
      this.setState((prevState) => ({
        ...prevState,
        shouldRenderPage: true,
      }))

      const user = userObj.user
      let pool = []
      const algoWallets =
        user?.wallets?.filter((wallet) => wallet.type === 'algorand') || []

      if (algoWallets.length > 0) {
        const addresses = algoWallets.map((obj) => {
          return obj.address
        })

        //if algoBalances fail - still show the algo addresses
        let AlgoAddresses = []
        try {
          AlgoAddresses = await fetchData('user/algoBalances', {
            accounts: addresses,
          })
        } catch (error) {
          console.log('🚀 ~ error in algoBalances', error)
        }

        pool = []
        algoWallets.forEach((wallet, index) => {
          pool.push({
            id: wallet._id,
            title: wallet.name,
            address: wallet.address,
            status: '',
          })

          //only add data value if there is algoBalance data
          if (AlgoAddresses[index]) {
            const algo = AlgoAddresses[index].balance
            const euro = algo * 1.56
            pool[index].data = {
              value: new BigNumber(algo).toFixed(5),
              unit: 'ALGO',
              eqValue: new BigNumber(euro).toFixed(2),
              eqUnit: '€',
            }
          }
        })
      }

      this.setState({ addresses: pool })
    } else {
      window.location.replace('/login')
    }
  }

  render() {
    const { addresses, expandedAddressId, shouldRenderPage, openModal } =
      this.state

    return (
      <>
        {shouldRenderPage && (
          <SettingsLayout title="Settings" activeTab="algorand-address">
            <ComponentWrapper>
              <Row>
                <Column>
                  <ComponentWrapper noPaddingHorizontal>
                    <SectionTitle>Settings</SectionTitle>
                  </ComponentWrapper>
                  <ComponentWrapper noPadding>
                    <Text>
                      <h3>
                        Algorand Addresses
                        <Tooltip
                          position="top"
                          title={`Registering your Algorand address is currently disabled until we have a new offer using the Algorand network.`}
                          theme="light"
                          arrow
                          duration={200}
                        >
                          <TooltipIcon2
                            style={{ marginLeft: 10, verticalAlign: 'super' }}
                          />
                        </Tooltip>
                      </h3>
                    </Text>
                  </ComponentWrapper>
                </Column>
              </Row>

              <Row>
                <Column part={9}>
                  <ComponentWrapper noPadding>
                    <FlexCenterContainer
                      noPaddingHorizontal
                      style={{ padding: '16px' }}
                    >
                      {/* <Text>
                        <h5>ADDRESSES MANAGEMENT</h5>
                      </Text>
                      <DataManagementCounter>
                        {addresses.map(address => address).length}
                      </DataManagementCounter>
                      <StatusBadge>verified</StatusBadge> */}
                    </FlexCenterContainer>
                    <Text>
                      <p>
                        From here you can manage all your Algorand addresses
                        which have been registered with STOKR.
                      </p>
                    </Text>
                  </ComponentWrapper>
                  <ComponentWrapper noPaddingHorizontal>
                    <Button
                      primary
                      onClick={() => {
                        // setRedirectCookie()

                        // window.location.replace(
                        //   'https://signup.stokr.io/register-algorand',
                        // )
                        this.setState({ openModal: !openModal })
                      }}
                      disabled
                    >
                      Add new address
                    </Button>
                  </ComponentWrapper>
                </Column>
              </Row>
            </ComponentWrapper>
            <ComponentWrapper noPadding>
              {addresses.map((address) => (
                <Fragment key={address.id}>
                  <CryptoAddress
                    title={address.title}
                    address={address.address}
                    data={address.data}
                    info={address.info}
                    status={address.status}
                    wrapped
                    borderTop
                  />
                  <Collapse isOpened={expandedAddressId === address.id}>
                    <CryptoAddressDetails
                      data={[
                        {
                          id: 1,
                          avatar: avatarImage,
                          name: 'Robo Sapiens',
                          balance: 'RSN 150',
                          transferClick: () => {},
                        },
                        {
                          id: 2,
                          avatar: avatarImage,
                          name: 'CoolBeanz',
                          balance: 'CBZ 150',
                          transferClick: () => {},
                        },
                        {
                          id: 3,
                          avatar: avatarImage,
                          name: 'Robin Radar',
                          balance: 'RRD 150',
                          transferClick: () => {},
                        },
                      ]}
                    />
                  </Collapse>
                </Fragment>
              ))}
            </ComponentWrapper>
          </SettingsLayout>
        )}
        {openModal && (
          <AlgoAddressFlowPage
            showFlow={openModal}
            setShowFlow={() => this.setState({ openModal: !openModal })}
            // setShowPending={setShowPending}
            withSuccessPage
          />
        )}
      </>
    )
  }
}

export default AlgorandAdresses
