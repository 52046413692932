import styled, { css } from 'styled-components'
import { Button, ComponentWrapper } from '@stokr/components-library'
import { Container as BackgroundContainer } from '@stokr/components-library'
import NotificationCounter from 'components/NotificationCounter/NotificationCounter.styles'
import { Modal } from 'components/Modal/Modal'
import { Box } from 'components/Modal/Modal.styles'
import rwd from 'styles/rwd'
import Icon, { iconsMap } from 'components/Icon/Icon.style'

export const AvatarContainer = styled.div`
  width: 88px;
  height: 88px;
  position: relative;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 16px;
  transition: 0.3s width, 0.3s height;
  flex-shrink: 0;
  box-shadow: none;

  ${rwd.Medium`
    margin-bottom: 0;
    margin-right: 32px;
  `}
`

export const AvatarExpandButton = styled.span`
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.cWarning};
  color: ${(props) => props.theme.cWhite};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
`

export const AvatarExpanded = styled.div`
  width: 300px;
  height: 300px;

  ${BackgroundContainer} {
    padding-top: 100%;
  }
`

export const FlexCenterContainer = styled(ComponentWrapper)`
  display: flex;
  align-items: center;
`

export const DataManagementCounter = styled(NotificationCounter)`
  margin-left: 8px;
`

export const AvatarModal = styled(Modal)`
  ${Box} {
    width: auto;
  }
`

export const FileInputWrapper = styled(Button).attrs({
  as: 'label',
  type: undefined,
})`
  position: relative;
  word-break: break-all;
  margin: 0;
`

export const FileInput = styled.input.attrs((props) => ({
  type: 'file',
  ...props,
}))`
  visibility: hidden;
  position: absolute;
  width: 0.1px;
  height: 0.1px;
  z-index: -1;
`

export const LinkInText = styled.a`
  &&& {
    color: ${(props) => props.theme.cBlack};

    &:hover {
      color: ${(props) => props.theme.cPrimary};
    }
  }
`

export const AvatarUploadContainer = styled(ComponentWrapper)`
  display: flex;
  align-items: center;
  flex-direction: column;

  ${rwd.Medium`
    flex-direction: row;
  `}
`

export const FileInputRemove = styled(Icon).attrs({
  icon: iconsMap.trash,
})`
  /* position: absolute; */
  /* top: 50%; */
  margin-right: 10px;
  right: 0px;
  /* transform: translateY(-50%); */
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s color;
  color: ${(props) => props.theme.cWarning};
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.cLightGrey};
  padding: 10px;

  &:hover {
    color: ${(props) => props.theme.cPrimary};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none !important;
      opacity: 0.2;
      cursor: default;
    `}
`
