import React, { useContext, useState, useEffect, useCallback } from 'react'
import {
  Button,
  ComponentWrapper,
  AuthContext,
  PaymentModal,
  CryptoAddress,
  getCurrencySymbol,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import { Container } from './ChartBox.styles'
import { FlexContainer } from '@stokr/components-library/dist/components/Grid/Grid.styles'
import RedemptionRequestModal from 'components/Redemption/RedemptionRequestModal'
import { EventDBContext } from 'context/EventDBContext/EventDBContext'
import NotificationsCarousel from 'components/NotificationsCarousel/NotificationsCarousel'
import { DisplayOnBreakpoint } from '@stokr/components-library/dist/styles/rwd'
import { TransactionTypes } from 'constants/enums'

const transactionTab = (isMobile = false, transactionsInProgress) => (
  <NotificationsCarousel
    dots
    isMobile={isMobile}
    onlyInvestmentNotifications
    isRedemptionRequest
    transactionsInProgress={transactionsInProgress}
  />
)

const Redemptions = () => {
  const { user, checkTokenIsValid } = useContext(AuthContext)
  const {
    projects,
    finalizedTx,
    pendingTx,
    isLoadingData,
    getTokenTransfers,
    documents,
  } = useContext(EventDBContext)

  const [noInvestments, setNoInvestments] = useState(false)
  const [noRedemptionInvestments, setnoRedemptionInvestments] = useState(false)
  const [redeemableAssets, setredeemableAssets] = useState([])
  const [redemptionsInProgress, setredemptionsInProgress] = useState([])
  const [redemptionSuccessData, setredemptionSuccessData] = useState()

  //modal state
  const [isModalOpen, setisModalOpen] = useState({
    redeemRequest: false,
    uploadDocument: false,
    redemptionSuccess: false,
  })

  useEffect(() => {
    let redeemableProjects = projects?.filter((project) => project.isRedeemable)

    if (redeemableProjects?.length) {
      const assetsToAdd = []
      redeemableProjects.forEach((project) => {
        const redemptionSale = project.sales?.find((sale) => sale.isRedemption)

        if (isRedemptionSaleOpen(redemptionSale)) {
          assetsToAdd.push({
            name: project.tokenSymbol,
            currencies: redemptionSale?.currencies || [],
            conversionPrice: redemptionSale?.conversionPrice,
            project,
          })
        }
      })

      setredeemableAssets(assetsToAdd)
    }
  }, [projects])

  useEffect(() => {
    if (!isLoadingData) {
      const transactions = [...pendingTx, ...finalizedTx]

      const redemptionTx = transactions.filter(
        (tx) => tx.isRedemption || tx.type === TransactionTypes.REDEMPTION,
      )

      redemptionTx.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      redemptionTx.forEach((tx) => {
        tx.handleCTAClick = handleRedemptionsDetailsClick
      })

      setredemptionsInProgress(redemptionTx)
      setnoRedemptionInvestments(redemptionTx.length === 0)
      setNoInvestments(transactions.length === 0)
    }
  }, [pendingTx, finalizedTx, isLoadingData])

  const toggleModal = useCallback((modalId, newState) => {
    setisModalOpen((prev) => ({
      ...prev,
      [modalId]: newState,
    }))
  }, [])

  const handleRedemptionsDetailsClick = useCallback(
    (transactionData) => {
      const projectFromTx = projects?.find(
        (project) => project._id === transactionData.project,
      )
      transactionData.projectData = projectFromTx
      setredemptionSuccessData(transactionData)

      setisModalOpen((prev) => {
        if (prev.redeemRequest) {
          return {
            ...prev,
            redeemRequest: false,
            redemptionSuccess: true,
          }
        } else {
          return {
            ...prev,
            redemptionSuccess: true,
          }
        }
      })
    },
    [projects, isModalOpen.redeemRequest, toggleModal],
  )

  return (
    <>
      {!isLoadingData && redemptionsInProgress?.length > 0 && (
        <ComponentWrapper center noPadding>
          <DisplayOnBreakpoint up breakpoint="Medium">
            {transactionTab(false, redemptionsInProgress)}
          </DisplayOnBreakpoint>
          <DisplayOnBreakpoint down breakpoint="Medium">
            {transactionTab(true, redemptionsInProgress)}
          </DisplayOnBreakpoint>
        </ComponentWrapper>
      )}
      <Container>
        <ComponentWrapper style={{ width: '100%' }} noPaddingVertical>
          {isLoadingData ? (
            <Text blockchainLoadingMsg>
              <h4>
                A few seconds left. We are still reading the data out of the
                blockchain...
              </h4>
            </Text>
          ) : noInvestments ? (
            <>
              <Text>
                <h4>
                  You have not made any investments yet. You want to see what
                  you can invest in? Please discover Investment Opportunities.
                </h4>
              </Text>
              <Button
                style={{ marginTop: '3rem' }}
                onClick={() =>
                  window.location.replace(
                    `https://${process.env.REACT_APP_WEBSITE_DOMAIN}/featured-assets`,
                  )
                }
              >
                INVESTMENT OPPORTUNITIES
              </Button>
            </>
          ) : (
            redeemableAssets.length > 0 && (
              <>
                {noRedemptionInvestments && (
                  <ComponentWrapper noPaddingHorizontal>
                    <Text>
                      <h4>You have not requested any redemptions yet.</h4>
                    </Text>
                  </ComponentWrapper>
                )}
                <FlexContainer style={{ gap: '32px', flexWrap: 'wrap' }}>
                  <Button
                    minWidth="240px"
                    onClick={() => {
                      checkTokenIsValid()
                      toggleModal('redeemRequest', true)
                    }}
                  >
                    Redeem
                  </Button>

                  {/* <Button onClick={() => toggleModal('uploadDocument', true)}>
                Upload Document(s)
              </Button> */}
                </FlexContainer>
              </>
            )
          )}
        </ComponentWrapper>

        <RedemptionRequestModal
          isModalOpen={isModalOpen.redeemRequest}
          user={user}
          redeemableAssets={redeemableAssets}
          transactions={redemptionsInProgress}
          onModalClose={() => toggleModal('redeemRequest', false)}
          onSuccess={handleRedemptionsDetailsClick}
        />
        <PaymentModal
          isModalOpen={isModalOpen.redemptionSuccess}
          user={user}
          data={redemptionSuccessData}
          txTextInRed={false}
          formatAmountInLink
          modalTitle={`SEND YOUR ${
            redemptionSuccessData?.tokenSymbol
          } to receive your 
              ${getCurrencySymbol(redemptionSuccessData?.currencyType)}`}
          sendTokenTextRight={
            <div style={{ marginBottom: '1em' }}>
              <p style={{ margin: 0, display: 'inline' }}>
                Send {redemptionSuccessData?.tokenAmount}{' '}
                {getCurrencySymbol(redemptionSuccessData?.tokenSymbol)} to the
                treasury wallet below. You will receive{' '}
                {redemptionSuccessData?.currencyAmount}{' '}
                {getCurrencySymbol(redemptionSuccessData?.currencyType)} on your
                AMP wallet:{' '}
              </p>
              <CryptoAddress
                noHead
                data={{
                  value: redemptionSuccessData?.GAID,
                  tooltip: true,
                  shortAddress: true,
                }}
                containerStyle={{ display: 'inline-block', height: 20 }}
                fontSize={16}
              />
            </div>
          }
          isRedemption
          onCTAClick={() => {
            toggleModal('redemptionSuccess', false)
            setredemptionSuccessData()
            getTokenTransfers(projects, documents, true)
          }}
          onModalClose={() => {
            toggleModal('redemptionSuccess', false)
            setredemptionSuccessData()
            getTokenTransfers(projects, documents, true)
          }}
        />
      </Container>
    </>
  )
}

const isRedemptionSaleOpen = (sale) => {
  const { startDate, endDate } = sale

  let today = new Date().getTime()
  const startTime = new Date(startDate).getTime()
  const endTime = new Date(endDate).getTime()

  return startTime < today && today < endTime
}

export default Redemptions
