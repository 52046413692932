import React, { Fragment } from 'react'
import Text from 'components/Text/Text.styles'
import { Button, ComponentWrapper, Checkbox } from '@stokr/components-library'
import { FormError, FormField } from 'components/Form/Form.styles'
import { Form, Formik } from 'formik'

import * as Yup from 'yup'
import parse from 'html-react-parser'

const BackupFormStep = ({ redemptionCheckboxes, redemptionForm }) => {
  const { formData, isUploading, handleSubmit } = redemptionForm

  const initialValues = Object.keys(redemptionCheckboxes).reduce(
    (acc, checkboxKey) => {
      if (checkboxKey !== 'confirmInformation') {
        acc[checkboxKey] = false
      }
      return acc
    },
    {},
  )

  const validationSchema = Yup.object().shape(
    Object.keys(redemptionCheckboxes).reduce((acc, checkboxKey) => {
      if (checkboxKey !== 'confirmInformation') {
        acc[checkboxKey] = Yup.boolean().oneOf([true], 'This field is required')
      }
      return acc
    }, {}),
  )

  const handleRedeemClick = () => {
    handleSubmit(formData)
  }

  return (
    <>
      <ComponentWrapper noPaddingBottom>
        <Text>
          <h3>Back-up your seed phrase</h3>
        </Text>
      </ComponentWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleRedeemClick(values)}
      >
        {({ values, handleChange, handleBlur, errors, touched }) => {
          const submitDisabled = Object.keys(redemptionCheckboxes)
            .filter((checkboxKey) => checkboxKey !== 'confirmInformation')
            .some((checkboxKey) => !values[checkboxKey])
          return (
            <Form>
              <ComponentWrapper>
                <Text>
                  <p>
                    Please confirm you have a back-up for the seed phrase of the
                    wallet holding your securities. If you lose access to your
                    wallet, please contact{' '}
                    <a
                      href="mailto:support@stokr.io"
                      style={{ color: 'inherit' }}
                    >
                      support@stokr.io
                    </a>
                    .
                  </p>
                </Text>
                {Object.keys(redemptionCheckboxes)
                  ?.filter(
                    (checkboxKey) =>
                      redemptionCheckboxes[checkboxKey].label !==
                      'confirmInformation',
                  )
                  .map((checkboxKey) => {
                    const checkbox = redemptionCheckboxes[checkboxKey]
                    return (
                      <ComponentWrapper
                        key={checkbox.label}
                        noPaddingBottom
                        noPaddingHorizontal
                      >
                        <FormField>
                          <Checkbox
                            id={checkbox.label}
                            name={checkbox.label}
                            text={parse(checkbox?.agreementText)}
                            value="yes"
                            checked={!!values[checkbox.label]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // error={!!errors[checkbox.label]}
                            touched={!!touched[checkbox.label]}
                          />
                          {/* {errors[checkbox.label] &&
                            touched[checkbox.label] && (
                              <FormError>{errors[checkbox.label]}</FormError>
                            )} */}
                        </FormField>
                      </ComponentWrapper>
                    )
                  })}
                <ComponentWrapper noPaddingBottom noPaddingHorizontal>
                  <Button
                    onClick={handleRedeemClick}
                    disabled={submitDisabled || isUploading}
                  >
                    {isUploading ? 'PROCESSING' : 'Redeem'}
                  </Button>
                </ComponentWrapper>
              </ComponentWrapper>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default BackupFormStep
