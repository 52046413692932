import styled, { css } from 'styled-components'
import { PasswordStrength } from './InputPassword'

export const BottomWrap = styled.div`
  position: relative;
  margin-top: 6px;
`

export const ShowPassword = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  transition: color 0.2s;
  font-size: 12px;
  line-height: 16px;
  padding: 12px 0;
  height: 40px;
  width: 20px;
  text-align: right;

  &:hover {
    color: ${(props) => props.theme.cPrimary};
  }
`

export const CapslockIndicator = styled.div`
  position: absolute;
  top: 4px;
  right: 2px;

  & > svg {
    fill: ${(props) => props.theme.cBlack};
  }
`

export const PasswordStrengthWrap = styled.div`
  padding: 3px 0;
  display: block;
  width: 100%;
  padding-right: 20px;
  font-size: 0;
`

export const PasswordStrengthIndicators = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 0 -4px;
  padding-right: 12px;

  ${(props) =>
    props.passwordStrength === PasswordStrength.WEAK &&
    css`
      ${PasswordStrengthIndicator} {
        &:nth-child(1) {
          background-color: ${props.theme.cWarning};
        }
      }
    `}

  ${(props) =>
    props.passwordStrength === PasswordStrength.MEDIUM &&
    css`
      ${PasswordStrengthIndicator} {
        &:nth-child(1),
        &:nth-child(2) {
          background-color: ${props.theme.cYellow};
        }
      }
    `}

  ${(props) =>
    props.passwordStrength === PasswordStrength.STRONG &&
    css`
      ${PasswordStrengthIndicator} {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          background-color: ${props.theme.cLighterGreen};
        }
      }
    `}
`

export const PasswordStrengthIndicator = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 6px;
  border-radius: 3px;
  margin: 3px 4px;
  background-color: ${(props) => props.theme.cGrey};
`

export const PasswordStrengthText = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-weight: 300;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.4px;
  font-style: italic;
  color: ${(props) => props.theme.cText};
`

export const DatePickerOverlay = styled.div`
  &&& > .DayPickerInput {
    z-index: 100;
    display: block;
    position: relative;
    width: 100%;
  }

  &&& > .DayPickerInput > input {
    font-family: 'Open sans';
    display: block;
    width: 100%;
    height: 40px;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 13px 0;
    padding-bottom: 7px;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    border: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.cGrey};
    color: ${(props) => props.theme.cBlack};
    background-color: transparent;
    transition: border-color 0.2s;

    &:focus,
    &:hover {
      box-shadow: none;
      border-top-color: transparent;
      border-bottom-color: ${(props) => props.theme.cPrimary};
    }

    &::placeholder {
      color: transparent;
      opacity: 0;
    }
  }
`

export const InfoIconWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: -2px 0;

  position: relative;
  left: 80px;
  top: 2.1px;

  transition: all 0.5s;

  ${({ isPassword }) =>
    isPassword &&
    css`
      opacity: ${({ labelUp }) => (labelUp ? 1 : 0)};
    `}
`
