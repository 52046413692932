import styled, { css } from 'styled-components'

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}
`

export const Subtitle = styled.div`
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 2.06px;
  text-transform: uppercase;

  ${({ centerVertically }) =>
    centerVertically &&
    css`
      margin-bottom: 0;
    `}

  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: ${marginLeft};
    `}
`
