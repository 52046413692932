import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'react-collapse'
import TabsNav from 'components/TabsNav/TabsNav'
import {
  ComponentWrapper,
  Row,
  Column,
  Tabs,
  Tab,
} from '@stokr/components-library'
import { loaderGif } from '@stokr/components-library/dist/components/StokrLoader/StokrLoader'

import Text from 'components/Text/Text.styles'
import LearnMoreCarousel from 'components/LearnMoreCarousel/LearnMoreCarousel'
import { LearnMoreSlide } from 'components/LearnMoreCarousel/LearnMoreCarousel.styles'
import { getMedia } from 'styles/rwd'
import {
  learnMorePostPropTypes,
  learnMoreCategoryPropTypes,
} from 'components/LearnMorePage/LearnMore'
import {
  TabNav,
  TabArrow,
  Container,
  TitleContainer,
  TabTitle,
  Arrow,
  SidebarSubHeading,
} from './LearnMore.styles'
import LearnMoreItem from './LearnMoreItem'

class LearnMore extends PureComponent {
  state = {
    activeTab: 0,
    isMobile: false,
  }

  componentDidMount() {
    const { categories } = this.props
    if (categories[0]) {
      this.onTabChange(categories[0].id)
    }
    window.addEventListener('resize', this.onResize)
    this.onResizeTimeout()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onTabChange = (tabId) => {
    this.setState({
      activeTab: tabId,
    })
  }

  onResize = () => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(this.onResizeTimeout, 200)
  }

  onResizeTimeout = () => {
    this.setState({
      isMobile: this.checkIsMobile(),
    })
  }

  checkIsMobile = () => !window.matchMedia(getMedia('Medium')).matches

  getItemsForSubcategories = (subcategories) => {
    const { items } = this.props
    return subcategories.map((subcategory) =>
      items.find((item) => item.id === subcategory.id),
    )
  }

  render() {
    const { categories, loading } = this.props
    const { activeTab, isMobile } = this.state

    return isMobile ? (
      <Container>
        <Row>
          <Column>
            <TitleContainer>
              <Text>
                {/* <p>INVESTING 101</p> */}
                <SidebarSubHeading>INVESTMENT OPPORTUNITIES</SidebarSubHeading>
                <h3>DISCOVER INVESTMENTS</h3>
              </Text>
            </TitleContainer>
            {loading ? (
              <ComponentWrapper center>
                <img src={loaderGif} style={{ width: '100%' }} alt="&nbsp;" />
              </ComponentWrapper>
            ) : (
              categories.map((category) => {
                const items = this.getItemsForSubcategories(
                  category.subcategories,
                )

                return (
                  <ComponentWrapper noPadding borderTop key={category.id}>
                    <TabTitle onClick={() => this.onTabChange(category.id)}>
                      {category.title}
                      <Arrow isOpened={activeTab === category.id} />
                    </TabTitle>
                    <Collapse isOpened={activeTab === category.id}>
                      <LearnMoreCarousel dots isMobile>
                        {items.map((item) => (
                          <LearnMoreSlide key={item.id}>
                            <LearnMoreItem
                              key={item.id}
                              title={item.title}
                              content={item.excerpt}
                              coverSrc={item.cover}
                              postType={item.postType}
                              link={item.url}
                              videoSrc={item.videoSrc}
                              category={item.category}
                            />
                          </LearnMoreSlide>
                        ))}
                      </LearnMoreCarousel>
                    </Collapse>
                  </ComponentWrapper>
                )
              })
            )}
          </Column>
        </Row>
      </Container>
    ) : (
      <Container>
        <Row>
          <Column part={5} style={{ height: 'auto' }}>
            <TitleContainer borderRight borderBottom>
              <Text responsiveFont>
                {/* <p>INVESTING 101</p> */}
                <SidebarSubHeading>INVESTMENT OPPORTUNITIES</SidebarSubHeading>
                <h3>DISCOVER INVESTMENTS</h3>
              </Text>
            </TitleContainer>
            <TabsNav activeTab={activeTab}>
              {categories.map((category) => (
                <TabNav
                  key={category.id}
                  borderBottom
                  borderRight
                  data-testid="tabNav"
                  tabId={category.id}
                  isActive={activeTab === category.id}
                  onClick={() => this.onTabChange(category.id)}
                >
                  <TabArrow />
                  {category.title}
                </TabNav>
              ))}
            </TabsNav>
            <TitleContainer borderRight>
              <Text></Text>
            </TitleContainer>
          </Column>
          <Column part={11}>
            {loading ? (
              <ComponentWrapper center>
                <img src={loaderGif} alt="&nbsp;" />
              </ComponentWrapper>
            ) : (
              <Tabs activeTab={activeTab}>
                {categories.map((category) => {
                  const items = this.getItemsForSubcategories(
                    category.subcategories,
                  )
                  return (
                    <Tab tabId={category.id} key={category.id}>
                      <LearnMoreCarousel arrows dots>
                        {items.map((item) => (
                          <LearnMoreSlide key={item.id}>
                            <LearnMoreItem
                              key={item.id}
                              title={item.title}
                              content={item.excerpt}
                              coverSrc={item.cover}
                              postType={item.postType}
                              link={item.url}
                              videoSrc={item.videoSrc}
                              category={item.category}
                            />
                          </LearnMoreSlide>
                        ))}
                        <LearnMoreSlide />
                      </LearnMoreCarousel>
                    </Tab>
                  )
                })}
              </Tabs>
            )}
          </Column>
        </Row>
      </Container>
    )
  }
}

LearnMore.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(learnMorePostPropTypes)).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      ...learnMoreCategoryPropTypes,
      subcategories: PropTypes.arrayOf(
        PropTypes.shape(learnMoreCategoryPropTypes),
      ),
    }),
  ).isRequired,
}

export default LearnMore
