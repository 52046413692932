import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { CapsLockSvg, InfoIcon } from '@stokr/components-library'
import passwordStrengthMeter from 'utils/password-strength-meter'
import { Wrapper, Label, InputWrap } from './Input.styles'
import {
  ShowPassword,
  BottomWrap,
  CapslockIndicator,
  PasswordStrengthIndicators,
  PasswordStrengthIndicator,
  PasswordStrengthWrap,
  PasswordStrengthText,
  InfoIconWrapper,
} from './InputPassword.styles'

export const PasswordStrength = {
  NONE: 'none',
  WEAK: 'Nuts! A squirrel could crack this',
  MEDIUM: 'Just medium, if you get our message',
  STRONG: 'The force is strong with this one',
}

class InputPassword extends PureComponent {
  state = {
    isPasswordVisible: false,
    isCapslockOn: false,
    labelUp: false,
    hasFocus: false,
  }

  componentDidMount() {
    this.checkLabel(false)
  }
  componentDidUpdate() {
    this.checkLabel(false)
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }))
  }

  handleCapslock = (e) => {
    this.setState({
      isCapslockOn: e.getModifierState('CapsLock'),
    })
  }

  passwordStrengthFunction = (password) => {
    const value = passwordStrengthMeter(password)

    if (value === 0) {
      return PasswordStrength.NONE
    }
    if (value === 1 || value === 2) {
      return PasswordStrength.WEAK
    }
    if (value === 3 || value === 4) {
      return PasswordStrength.MEDIUM
    }
    return PasswordStrength.STRONG
  }

  onFocus = (e, field) => {
    const { onFocus } = this.props
    onFocus && onFocus(e, field)

    this.checkLabel(true)
  }

  onBlur = (e, field) => {
    const { onBlur } = this.props
    onBlur && onBlur(e, field)

    this.checkLabel(false)
  }

  onChange = (e, field) => {
    const { onChange } = this.props
    onChange && onChange(e, field)
  }

  checkLabel = (focus) => {
    const { value } = this.props

    this.setState({
      labelUp: focus || !!value,
      hasFocus: focus,
    })
  }

  render() {
    const { isPasswordVisible, isCapslockOn, labelUp, hasFocus } = this.state
    const { label, id, name, value, error, touched, info, showStrength } =
      this.props
    const passwordStrength = this.passwordStrengthFunction(value)

    return (
      <Wrapper>
        {label && (
          <Label
            isUp={labelUp}
            active={hasFocus}
            error={error && touched}
            htmlFor={id}
          >
            {label}
          </Label>
        )}
        {info && (
          <InfoIconWrapper isPassword labelUp={labelUp}>
            <InfoIcon position="bottom" title={info} noMarginLeft />
          </InfoIconWrapper>
        )}
        <InputWrap error={error && touched}>
          <input
            type={isPasswordVisible ? 'text' : 'password'}
            id={id}
            name={name}
            value={value}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onKeyUp={this.handleCapslock}
            onKeyDown={this.handleCapslock}
          />
        </InputWrap>
        <ShowPassword onClick={this.togglePasswordVisibility}>
          {isPasswordVisible ? (
            <i className="ion ion-md-eye-off" />
          ) : (
            <i className="ion ion-md-eye" />
          )}
        </ShowPassword>
        <BottomWrap>
          {showStrength ? (
            <PasswordStrengthWrap>
              <PasswordStrengthIndicators passwordStrength={passwordStrength}>
                <PasswordStrengthIndicator />
                <PasswordStrengthIndicator />
                <PasswordStrengthIndicator />
              </PasswordStrengthIndicators>
              {/* {info && (
                <InfoIconWrapper>
                  <InfoIcon position="bottom" title={info} noMarginLeft />
                </InfoIconWrapper>
              )} */}
              <PasswordStrengthText passwordStrength={passwordStrength}>
                {passwordStrength !== PasswordStrength.NONE && passwordStrength}
              </PasswordStrengthText>
            </PasswordStrengthWrap>
          ) : (
            // info && (
            //   <InfoIconWrapper>
            //     <InfoIcon position="bottom" title={info} noMarginLeft />
            //   </InfoIconWrapper>
            // )
            <></>
          )}
          <CapslockIndicator>
            {isCapslockOn && <CapsLockSvg />}
          </CapslockIndicator>
        </BottomWrap>
      </Wrapper>
    )
  }
}

InputPassword.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  info: PropTypes.string,
  showStrength: PropTypes.bool,
}

InputPassword.defaultProps = {
  label: '',
  value: '',
  error: false,
  touched: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  info: '',
  showStrength: false,
}

export default InputPassword
