import PropTypes from 'prop-types'
import React from 'react'

import { Container, Wrap, Items, Item } from './StepsProgress.styles'

const StepsProgress = ({ items, doneIndex, activeIndex }) => (
  <Container>
    <Wrap>
      <Items>
        {items.map((item, key) => (
          <Item
            key={item.id}
            isDone={key <= doneIndex}
            isActive={key === activeIndex}
            onClick={() => {
              item.handleClick && item.handleClick(item.id)
            }}
          />
        ))}
      </Items>
    </Wrap>
  </Container>
)

StepsProgress.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  doneIndex: PropTypes.number,
  activeIndex: PropTypes.number,
}

StepsProgress.defaultProps = {
  doneIndex: -1,
  activeIndex: -1,
}

/**

example props

items={[
  { id: 'step-1', handleClick: (id) => { console.log(id); } },
  { id: 'step-2', handleClick: (id) => { console.log(id); } },
  { id: 'step-3', handleClick: (id) => { console.log(id); } },
  { id: 'step-4', handleClick: (id) => { console.log(id); } },
  { id: 'step-5', handleClick: (id) => { console.log(id); } },
  { id: 'step-6', handleClick: (id) => { console.log(id); } },
]}
doneIndex={2}
activeIndex={3}

*/

export default StepsProgress
