import React, { Fragment } from 'react'
import Text from 'components/Text/Text.styles'
import {
  Button,
  ComponentWrapper,
  FormInfo,
  Checkbox,
  SectionTitle,
  CryptoAddress,
  useMobileView,
} from '@stokr/components-library'
import { FormError, FormField } from 'components/Form/Form.styles'
import Select from 'components/Input/Select'
import { Field, Form, Formik } from 'formik'
import Input from 'components/Input/Input'
import {
  ButtonWrap,
  Container,
} from '@stokr/components-library/dist/components/Input/InputWithButton.styles'
import { FAQ } from '@stokr/components-library/dist/components/FAQ/FAQ'
import { Collapse } from 'react-collapse'
import { SelectIcon } from 'components/Input/Select.styles'
import * as Yup from 'yup'
import parse from 'html-react-parser'
import { getCurrencySymbol } from '@stokr/components-library/dist/utils/formatCurrencyValue'

const RequestFormStep = ({
  changeStep,
  redeemableAssets,
  redemptionCheckboxes,
  redemptionForm,
}) => {
  const {
    userWalletWithBalances,
    isWalletCollapseOpened,
    setisWalletCollapseOpened,
    selectedWallet,
    setselectedWallet,
    setformData,
    formData,
  } = redemptionForm

  const isMobile = useMobileView('450px')

  const RedemptionRequestSchema = Yup.object().shape({
    amount: Yup.number()
      .required('You cannot redeem 0 securities')
      .moreThan(0, 'You cannot redeem 0 securities')
      .test(
        'max-balance',
        'The number of securities exceeds your remaining balance',
        function (value) {
          const { wallet, asset } = this.parent
          if (wallet && asset) {
            const selectedWallet = userWalletWithBalances.find(
              (w) => w.address === wallet,
            )
            const balance =
              selectedWallet?.balances?.find((b) => b.asset === asset)
                ?.balance || 0
            return value <= balance
          } else return true
        },
      ),
  })

  return (
    <>
      <ComponentWrapper noPaddingBottom>
        <Text>
          <h3>Request a Redemption</h3>
        </Text>
      </ComponentWrapper>
      <ComponentWrapper>
        <Formik
          initialValues={{
            asset: formData?.asset || '',
            wallet: formData?.wallet || '',
            currency: formData?.currency || '',
            amount: formData?.amount || '',
            confirmInformation: formData?.confirmInformation || false,
          }}
          validationSchema={RedemptionRequestSchema}
          onSubmit={(values) => {
            setformData(values)
            changeStep()
            // handleSubmit(values)
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            handleChange,
          }) => {
            const handleChangeSelect = (field) => {
              setFieldValue(field.name, field.value, false)
              setFieldTouched(field.name, true, false)
            }

            const handleSetMaxAmount = async () => {
              const balance =
                selectedWallet?.balances?.find((b) => b.asset === values.asset)
                  ?.balance || 0

              await setFieldValue('amount', balance.toString(), false)
              await setFieldTouched('amount', true, true)
            }

            //set fields tocuhed when coming back from second step - to make button enabled
            if (formData && Object.keys(touched).length === 0) {
              Object.keys(formData).forEach((field) => {
                setFieldTouched(field, true)
              })
            }

            const submitDisabled =
              !touched.asset ||
              !!errors.asset ||
              !values.wallet ||
              !touched.amount ||
              !!errors.amount ||
              !touched.currency ||
              !!errors.currency ||
              !values.confirmInformation

            return (
              <Form>
                {/* Select securities */}
                <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                  <FormField>
                    <Select
                      id="asset"
                      name="asset"
                      value={values.asset}
                      options={redeemableAssets?.map((asset) => {
                        return {
                          value: asset.name,
                          label: asset.name,
                          key: asset.name,
                        }
                      })}
                      label="Choose your securities:"
                      onChange={handleChangeSelect}
                      onBlur={(e) => {}}
                    />
                    <FormError show={errors.asset && touched.asset}>
                      {errors.asset}
                    </FormError>
                  </FormField>
                </ComponentWrapper>

                {/* Custom select wallet */}
                <ComponentWrapper
                  noPaddingHorizontal
                  noPaddingBottom={!isWalletCollapseOpened}
                  borderBottom={isWalletCollapseOpened}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '0 8px 5px 0',
                      cursor: 'pointer',
                      borderBottom: '1px solid #e1e1e1',
                      alignItems: 'center',
                    }}
                    onClick={() =>
                      setisWalletCollapseOpened(!isWalletCollapseOpened)
                    }
                  >
                    {!isWalletCollapseOpened && selectedWallet ? (
                      <div style={{ width: '100%' }}>
                        <SectionTitle>{'Choose your AMP wallet:'}</SectionTitle>
                        <CryptoAddress
                          noHead
                          eqValueFontSize={13}
                          fontSize={16}
                          data={{
                            value: selectedWallet.address,
                          }}
                          eqValueStyle={isMobile ? { marginTop: 0 } : {}}
                          infoBoxStyle={{ padding: 0 }}
                          info={{
                            eqValue: values.asset
                              ? selectedWallet.balances
                                ? `${values.asset} ${
                                    selectedWallet.balances?.find(
                                      (x) => x.asset === values.asset,
                                    )?.balance ?? 0
                                  }`
                                : `${values.asset} 0`
                              : '',
                          }}
                        />
                      </div>
                    ) : (
                      <SectionTitle>{'Choose your AMP wallet:'}</SectionTitle>
                    )}
                    <SelectIcon isMenuOpen={isWalletCollapseOpened} />
                  </div>

                  <Collapse isOpened={isWalletCollapseOpened}>
                    <Field name="wallet" disabled>
                      {({ field }) => {
                        return (
                          <Fragment>
                            {userWalletWithBalances
                              ?.sort((a, b) => {
                                if (!values.asset) return 0
                                //sort based on balance in the wallet
                                const balanceA =
                                  a.balances.find(
                                    (balance) => balance.asset === values.asset,
                                  )?.balance || 0

                                const balanceB =
                                  b.balances.find(
                                    (balance) => balance.asset === values.asset,
                                  )?.balance || 0

                                return balanceB - balanceA
                              })
                              .map((wallet, i) => {
                                let balance = wallet.balances?.find(
                                  (x) => x.asset === values.asset,
                                )

                                return (
                                  <CryptoAddress
                                    key={i}
                                    noHead
                                    eqValueFontSize={13}
                                    dataBoxStyle={{
                                      padding: '5px 0',
                                    }}
                                    infoBoxStyle={{
                                      padding: `${
                                        isMobile ? '0 0 10px 0' : '5px 0'
                                      }`,
                                    }}
                                    fontSize={16}
                                    data={{
                                      value: wallet.address,
                                    }}
                                    eqValueStyle={
                                      isMobile ? { marginTop: -5 } : {}
                                    }
                                    info={{
                                      eqValue: values.asset
                                        ? balance
                                          ? `${values.asset} ${balance.balance}`
                                          : `${values.asset} 0`
                                        : '',
                                    }}
                                    radio={{
                                      id: 'wallet' + i,
                                      name: field.name,
                                      hideLine: true,
                                      value: wallet.address,
                                      address: wallet,
                                      onChange: field.onChange,
                                      onBlur: field.onBlur,
                                      handleCheckedAddressRadio: (e) => {},
                                      handleSelectedCryptoAddress: (data) => {
                                        // data.selectedAsset=balance
                                        setselectedWallet(data)
                                        if (isWalletCollapseOpened) {
                                          setisWalletCollapseOpened(false)
                                        }
                                      },
                                    }}
                                  />
                                )
                              })}
                          </Fragment>
                        )
                      }}
                    </Field>
                  </Collapse>
                </ComponentWrapper>

                {/* amount input */}
                <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                  <FormField>
                    <Container>
                      <Input
                        id="amount"
                        name="amount"
                        type="number"
                        value={values.amount}
                        autoHeightLabel
                        label="Number of securities to redeem"
                        onChange={(e) => {
                          const tokenDecimals =
                            selectedWallet?.balances?.find(
                              (b) => b.asset === values.asset,
                            )?.tokenDecimals || 0

                          const value = handleDecimalInput(e, tokenDecimals)
                          let eCopy = {
                            ...e,
                            target: {
                              ...e.target,
                              value,
                              name: e.target.name,
                            },
                          }
                          handleChange(eCopy)
                        }}
                        onBlur={handleBlur}
                        error={!!errors.amount}
                        touched={!!touched.amount}
                        wrapperStyle={{ width: '100%' }}
                      />
                      <ButtonWrap>
                        <Button onClick={handleSetMaxAmount}>All</Button>
                      </ButtonWrap>
                    </Container>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent:
                          errors.amount && touched.amount
                            ? 'space-between'
                            : 'end',
                      }}
                    >
                      <FormError show={errors.amount && touched.amount}>
                        {errors.amount}
                      </FormError>
                      {values.asset && values.wallet ? (
                        <FormInfo
                          style={{
                            opacity: 1,
                            textAlign: 'end',
                            paddingTop: 3,
                          }}
                        >
                          Remaining balance:{' '}
                          {calculateRemainingBalance(
                            selectedWallet,
                            values.amount,
                            values.asset,
                          )}
                        </FormInfo>
                      ) : (
                        <FormInfo> </FormInfo>
                      )}
                    </div>
                  </FormField>
                </ComponentWrapper>

                {/* currency select */}
                <ComponentWrapper noPaddingHorizontal>
                  <FormField>
                    <Select
                      id="currency"
                      name="currency"
                      value={values.currency}
                      options={
                        redeemableAssets
                          ?.find((asset) => asset.name === values.asset)
                          ?.currencies?.map((currency) => {
                            return {
                              value: currency,
                              label: getCurrencySymbol(currency),
                              key: currency,
                            }
                          }) || []
                      }
                      label="Redemption currency"
                      disabled={!values.asset}
                      onChange={handleChangeSelect}
                      onBlur={(e) => {}}
                    />
                    {!values.asset && (
                      <FormInfo>The Securities must be selected first</FormInfo>
                    )}
                    <FormError show={errors.currency && touched.currency}>
                      {errors.currency}
                    </FormError>
                  </FormField>
                </ComponentWrapper>

                {/* checkbox confirmation */}
                <ComponentWrapper noPaddingBottom noPaddingHorizontal>
                  <FormField>
                    <Checkbox
                      id={redemptionCheckboxes.confirmInformation.label}
                      name="confirmInformation"
                      text={parse(
                        redemptionCheckboxes.confirmInformation?.agreementText,
                      )}
                      value="yes"
                      checked={!!values.confirmInformation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.confirmInformation}
                      touched={!!touched.confirmInformation}
                    />
                    <FormError
                      show={
                        errors.confirmInformation && touched.confirmInformation
                      }
                    >
                      {errors.confirmInformation}
                    </FormError>
                  </FormField>
                </ComponentWrapper>

                <ComponentWrapper noPaddingBottom noPaddingHorizontal>
                  <Button type="submit" disabled={submitDisabled}>
                    Continue
                  </Button>
                </ComponentWrapper>
              </Form>
            )
          }}
        </Formik>
      </ComponentWrapper>

      <ComponentWrapper noPaddingVertical>
        <SectionTitle>Frequently Asked Questions</SectionTitle>
      </ComponentWrapper>
      <ComponentWrapper>
        <FAQ
          items={[
            {
              title: 'What is the remaining balance?',
              content:
                'The remaining balance is the total number of securities on your wallet minus the number of securities you requested a redemption. ',
            },
            {
              title: 'On which AMP wallet will I receive the payout?',
              content:
                'You will receive on the AMP wallet holding the BMN1. Please make sure you have saved your seed phrase in a secure place. If your private key is lost, destroyed or otherwise compromised and no backup of the private key is accessible, your investments might be lost forever.',
            },
            {
              title: 'I made a redemption request, what are the next steps?',
              content: (
                <>
                  Please continue by sending the BMN1 to the treasury wallet. We
                  have transfered 0.00002 LBTC to cover your transaction costs.
                  The treasury wallet will be provided to you after requesting a
                  redemption. Click{' '}
                  <a
                    href="https://stokr.zendesk.com/hc/en-us/articles/14275775984284-BMN1-redemption-guide"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: 'underline' }}
                  >
                    here
                  </a>{' '}
                  for more information on the redemption flow.
                </>
              ),
            },
          ]}
        />
      </ComponentWrapper>
    </>
  )
}

const calculateRemainingBalance = (wallet, amount, asset) => {
  let walletBalances = wallet?.balances?.find((x) => x.asset === asset)
  let remainingAmount = walletBalances?.balance - amount

  if (!remainingAmount || remainingAmount <= 0) return '0'
  return remainingAmount.toFixed(walletBalances?.tokenDecimals)
}

const handleDecimalInput = (event, decimalPlaces) => {
  let value = event.target.value

  // If tokenDecimals is 0, remove any decimals
  if (decimalPlaces === 0) {
    value = value.split('.')[0]
  } else if (value.includes('.')) {
    const [integer, decimals] = value.split('.')
    if (decimals.length > decimalPlaces) {
      value = `${integer}.${decimals.slice(0, decimalPlaces)}`
    }
  }

  return value
}

export default RequestFormStep
