import styled from 'styled-components'
import rwd from 'styles/rwd'

export const Container = styled.div`
  display: block;
  width: 100%;
  height: 100%;
`

export const Wrap = styled.div`
  display: block;
  margin: 0 auto;
`

export const Items = styled.div`
  display: flex;
  height: 100%;
  font-size: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 0 -6px;
  text-align: center;

  ${rwd.Medium`
    display: block;
  `}
`

export const Item = styled.button`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  flex: 1;
  margin: 0 4px;
  height: 32px;
  max-width: 32px;
  cursor: pointer;

  &:before {
    z-index: 0;
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    position: absolute;
    top: 14px;
    left: 0;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.05);
    background-color: ${(props) => props.theme.cGrey};
  }

  &:after {
    z-index: 1;
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    position: absolute;
    top: 14px;
    left: 0;
    border-radius: 3px;
    background-color: transparent;
    transition: background-color 0.2s;
  }

  &:hover:after {
    background-color: ${(props) => props.theme.cText};
  }

  ${(props) =>
    props.isDone &&
    `
    &:after {
      background-color: ${props.theme.cProgressDone};
    }

    &:hover:after {
      background-color: ${props.theme.cText};
    }
  `}

  ${(props) =>
    props.isActive &&
    `
    &:after {
      background-color: ${props.theme.cGrey2};
    }

    &:hover:after {
      background-color: ${props.theme.cText};
    }
  `}

  ${rwd.Medium`
    width: 32px;
  `}
`
