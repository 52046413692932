import React, {
  PureComponent,
  Fragment,
  useEffect,
  useState,
  useRef,
} from 'react'
import { Collapse } from 'react-collapse'
import { useNavigate } from 'react-router-dom'
import {
  ComponentWrapper,
  ProfileBox,
  ProfileBadge,
  Tabs,
  Tab,
  VerifiedBadge,
  UserChecklist,
  AuthProvider,
  Button,
  AuthContext,
} from '@stokr/components-library'
import { loaderGif } from '@stokr/components-library/dist/components/StokrLoader/StokrLoader'
import ProfileStat from 'components/ProfileStat/ProfileStat'
import ExpandButton from 'components/ExpandButton/ExpandButton'
import NotificationsCarousel from 'components/NotificationsCarousel/NotificationsCarousel'
import { DisplayOnBreakpoint } from 'styles/rwd'
import { EventDBConsumer } from 'context/EventDBContext/EventDBContext'
import { NotificationConsumer } from 'context/NotificationContext/NotificationContext'
import {
  Container,
  Main,
  AvatarBox,
  InfoBox,
  InfoInner,
  Badges,
  Badge,
  Stats,
  Stat,
  Togglers,
  Toggler,
  TabsContainer,
  LoaderImg,
} from './ProfileHeader.styles'
import avatarPlaceholder from 'static/images/avatar-placeholder.png'
import { Subtitle, TitleContainer } from 'components/ToDoList/ToDoList.styles'
import ToDoListModal from 'components/ToDoList/ToDoListModal'
import { withRouter } from 'utils/withRouter'

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const UserAvatarComponent = (props) => {
  const { avatar, authUser } = props
  const [finalAvatar, setFinalAvatar] = useState(undefined)
  const prevProps = usePrevious({ avatar })
  let navigate = useNavigate()

  useEffect(() => {
    checkUserPhoto(avatar)
  }, [])

  useEffect(() => {
    if (avatar != prevProps?.avatar) {
      checkUserPhoto(avatar)
    }

    return () => {
      if (prevProps != null) {
        prevProps.avatar = avatar
      }
    }
  }, [avatar])

  const checkUserPhoto = (avatar) => {
    try {
      const http = new XMLHttpRequest()
      http.open('HEAD', avatar, true)
      http.send()

      if (http.status !== 404) {
        setFinalAvatar(avatar)
      }
    } catch (error) {
      console.log('error: ', error)
    }
  }

  return (
    <ProfileBox
      name={authUser.username ? authUser.username : authUser.email}
      pictureSrc={finalAvatar || avatarPlaceholder}
      handleEdit={() => {
        navigate('/settings/profile-and-privacy')
      }}
    />
  )
}

function NotificationTab({ isMobile, notifications, isLoading }) {
  return !isLoading ? (
    <NotificationsCarousel
      dots
      isMobile={isMobile}
      notifications={notifications}
    />
  ) : (
    <ComponentWrapper center>
      <LoaderImg src={loaderGif} alt="&nbsp;" />
    </ComponentWrapper>
  )
}

const PANEL = {
  NOTIFICATIONS: 'notifications',
  TODOS: 'todos',
}

const AdditionalTask = () => {
  const [additionalDocsModal, setAdditionalDocsModal] = React.useState(false)

  function toggleToDoList() {
    if (additionalDocsModal) {
      setAdditionalDocsModal(false)
    } else {
      setAdditionalDocsModal(true)
    }
  }
  return (
    <>
      <ComponentWrapper noPaddingTop noPaddingBottom noPaddingHorizontal>
        <TitleContainer marginBottom="10px">
          <Subtitle marginLeft="32px">Additional Document(s)</Subtitle>
        </TitleContainer>
      </ComponentWrapper>
      <ComponentWrapper noPaddingTop noPaddingHorizontal>
        <Button
          style={{ marginTop: 12, marginLeft: 5 }}
          onClick={toggleToDoList}
          data-cy="uploadButton"
        >
          Upload Document(s)
        </Button>
      </ComponentWrapper>

      <ToDoListModal
        isModalOpen={additionalDocsModal}
        onModalClose={toggleToDoList}
      />
    </>
  )
}

class ProfileHeader extends PureComponent {
  constructor(props) {
    super(props)
    this.tabScroll = React.createRef()
    this.state = {
      extendedPanel: null,
      ether: 0,
      euro: 0,
      mandatoryTasksCount: 0,
      walletsTasksCount: 0,
    }
  }

  static contextType = AuthContext

  async componentDidMount() {
    const { isChecklist } = this.props

    isChecklist && this.togglePanel(PANEL.TODOS, false)
    !isChecklist && this.togglePanel(PANEL.NOTIFICATIONS, false)
  }

  togglePanel = (panelId, forceScroll = true) => {
    const { extendedPanel } = this.state
    const { location } = this.props

    this.setState({
      extendedPanel: extendedPanel !== panelId ? panelId : null,
    })

    //we only don't want scroll behavious on mount if there's another route
    if (!location.pathname?.includes('overview') || forceScroll) {
      const tabsNavOffsetTop = this.tabScroll.current.offsetTop

      let scrollToPosition = tabsNavOffsetTop + 1

      window.scrollTo({ top: scrollToPosition, behavior: 'smooth' })
    }
  }

  render() {
    const { extendedPanel, mandatoryTasksCount, walletsTasksCount } = this.state

    const { user, avatar, getUser } = this.context

    const badge = user.badge ? user.badge : 'Early Adopter'

    return (
      <EventDBConsumer>
        {({ totalInvestment, dollarInEuro }) => (
          <Container>
            <NotificationConsumer>
              {({
                notifications,
                unreadNotificationsCount,
                isLoadingNotifications,
              }) => (
                <Fragment>
                  <Main>
                    <AvatarBox>
                      <UserAvatarComponent authUser={user} avatar={avatar} />
                    </AvatarBox>
                    <InfoBox>
                      <InfoInner>
                        <Badges>
                          <Badge
                            onClick={() => {
                              this.setState({
                                extendedPanel: PANEL.TODOS,
                              })
                              const tabsNavOffsetTop =
                                this.tabScroll.current.offsetTop

                              let scrollToPosition = tabsNavOffsetTop + 1

                              if (window.innerWidth < 768) {
                                scrollToPosition = tabsNavOffsetTop
                              }

                              window.scrollTo({
                                top: scrollToPosition,
                                behavior: 'smooth',
                              })
                            }}
                            isClickable
                          >
                            <ProfileBadge
                              title={
                                mandatoryTasksCount + walletsTasksCount === 0
                                  ? 'READY TO INVEST'
                                  : 'NOT READY TO INVEST'
                              }
                              icon={
                                mandatoryTasksCount + walletsTasksCount ===
                                  0 && <VerifiedBadge />
                              }
                              color="red"
                            />
                          </Badge>
                          <Badge borderBottom>
                            <ProfileBadge title={badge} withIcon />
                          </Badge>
                        </Badges>
                        <Stats>
                          <Stat>
                            <ProfileStat
                              label="Total Investment"
                              unit="€"
                              value={
                                isFinite(totalInvestment)
                                  ? totalInvestment.toFixed(2)
                                  : ''
                              }
                              eqUnit="$"
                              eqValue={
                                isFinite(totalInvestment)
                                  ? (totalInvestment / dollarInEuro).toFixed(2)
                                  : ''
                              }
                            />
                          </Stat>
                        </Stats>
                      </InfoInner>
                      <Togglers>
                        <Toggler>
                          <ExpandButton
                            greenCounter
                            title="Notifications"
                            counter={unreadNotificationsCount}
                            isOpened={extendedPanel === PANEL.NOTIFICATIONS}
                            onClick={() => {
                              this.togglePanel(PANEL.NOTIFICATIONS)
                            }}
                          />
                          <DisplayOnBreakpoint down breakpoint="Medium">
                            <Collapse
                              isOpened={extendedPanel === PANEL.NOTIFICATIONS}
                            >
                              <NotificationTab
                                isMobile
                                notifications={notifications}
                                isLoading={isLoadingNotifications}
                              />
                            </Collapse>
                          </DisplayOnBreakpoint>
                        </Toggler>
                        <Toggler ref={this.tabScroll}>
                          <ExpandButton
                            title="Checklist"
                            counter={mandatoryTasksCount + walletsTasksCount}
                            isOpened={extendedPanel === PANEL.TODOS}
                            onClick={() => {
                              this.togglePanel(PANEL.TODOS)
                            }}
                          />
                          <DisplayOnBreakpoint down breakpoint="Medium">
                            <Collapse
                              isOpened={extendedPanel === PANEL.TODOS}
                              theme={{ collapse: 'collapse-test' }}
                            >
                              <ComponentWrapper>
                                <UserChecklist
                                  user={user}
                                  redirectCookie
                                  additionalTasks={<AdditionalTask />}
                                  getTasksCount={(taskCount) => {
                                    this.setState({
                                      mandatoryTasksCount:
                                        taskCount.mandatoryTasksCount,
                                      walletsTasksCount:
                                        taskCount.walletTasksCount,
                                    })
                                  }}
                                  onTaxIdComplete={() => {
                                    getUser()
                                  }}
                                />
                              </ComponentWrapper>
                            </Collapse>
                          </DisplayOnBreakpoint>
                        </Toggler>
                      </Togglers>
                    </InfoBox>
                  </Main>
                  <DisplayOnBreakpoint up breakpoint="Medium">
                    <TabsContainer>
                      <ComponentWrapper noPadding borderBottom>
                        <Collapse isOpened={extendedPanel !== null}>
                          <Tabs activeTab={extendedPanel}>
                            <Tab tabId={PANEL.NOTIFICATIONS}>
                              <NotificationTab
                                notifications={notifications}
                                isLoading={isLoadingNotifications}
                              />
                            </Tab>
                            <Tab tabId={PANEL.TODOS}>
                              <ComponentWrapper>
                                <UserChecklist
                                  user={user}
                                  redirectCookie
                                  additionalTasks={<AdditionalTask />}
                                  getTasksCount={(taskCount) => {
                                    this.setState({
                                      mandatoryTasksCount:
                                        taskCount.mandatoryTasksCount,
                                      walletsTasksCount:
                                        taskCount.walletTasksCount,
                                    })
                                  }}
                                  onTaxIdComplete={() => {
                                    getUser()
                                  }}
                                />
                              </ComponentWrapper>
                            </Tab>
                          </Tabs>
                        </Collapse>
                      </ComponentWrapper>
                    </TabsContainer>
                  </DisplayOnBreakpoint>
                </Fragment>
              )}
            </NotificationConsumer>
          </Container>
        )}
      </EventDBConsumer>
    )
  }
}

export default withRouter(ProfileHeader)
