export default function(password) {
  // Create an array and push all possible values that you want in password
  var matchedCase = []
  matchedCase.push('[a-z]') // Lowercase
  matchedCase.push('[A-Z]') // Uppercase
  matchedCase.push('[0-9]') // Number
  matchedCase.push('[.,$@$!%*#?&]') // Symbol

  // Check the conditions
  let count = 0
  for (var i = 0; i < matchedCase.length; i++) {
    if (new RegExp(matchedCase[i]).test(password)) {
      count++
    }
  }

  // Check if is at least 8 characters long
  if (password.length >= 8) {
    count++
  }

  return count
}
