import styled from 'styled-components'
import { Form } from 'formik'
import rwd from 'styles/rwd'

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const FormField = styled.div`
  position: relative;
`

export const FormInfo = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 9px;
  line-height: 1.78em;
  letter-spacing: 0.4px;
  color: ${(props) => props.theme.cBlack};
  opacity: 1;
  ${(props) => props.center && 'text-align: center;'}
  ${(props) => props.marginTop && 'margin-top: 18px;'}
  ${(props) => props.marginBottom && 'margin-bottom: 18px;'}
  
  ${rwd.Large`
    font-size: 12px;
  `}

  a {
    text-decoration: underline;
    transition: color 0.2s;

    &:hover {
      color: ${(props) => props.theme.cPrimary};
    }
  }
`

export const FormError = styled.div`
  display: none;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 11px;
  line-height: 12px;
  margin: 0;
  position: relative;
  left: 0;
  top: 2px;
  margin-bottom: -12px;
  letter-spacing: 0.4px;
  color: ${(props) => props.theme.cWarning};

  ${(props) => props.show && 'display: block;'}
`
