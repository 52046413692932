import React, { Fragment, useContext, useEffect, useState } from 'react'
import SettingsLayout from 'components/SettingsLayout/SettingsLayout'
import {
  Button,
  Row,
  Column,
  ComponentWrapper,
  SectionTitle,
  CryptoAddress,
  AuthContext,
} from '@stokr/components-library'
import Text from 'components/Text/Text.styles'
import { FlexCenterContainer } from 'components/Settings/Settings.styles'
import setRedirectCookie from 'utils/set-redirect-cookie'

const LiquidAddress = (props) => {
  const { user } = useContext(AuthContext)
  const [addresses, setAddresses] = useState([])
  const [expandedAddressId, setExpandedAddressId] = useState(null)

  const toggleAddress = (addressId) => {
    setExpandedAddressId(expandedAddressId !== addressId ? addressId : null)
  }

  const removeAddress = (addressId) => {
    setAddresses(addresses.filter((address) => address.id !== addressId))
  }
  useEffect(() => {
    const { wallets = [] } = user

    let pool = []
    const liquidGenericWallets = wallets.filter(
      (wallet) => wallet.type === 'liquid' && !wallet.assetIds,
    )

    if (liquidGenericWallets.length > 0) {
      pool = []

      liquidGenericWallets.forEach((wallet) => {
        pool.push({
          id: wallet._id,
          title: wallet.name,
          address: wallet.address,
          data: {
            value: wallet.address,
            tooltip: true,
          },
          status: '',
        })
      })
    }

    setAddresses(pool)
  }, [user])

  return (
    <>
      {
        <SettingsLayout title="Settings" activeTab="liquid-address">
          <ComponentWrapper>
            <Row>
              <Column>
                <ComponentWrapper noPaddingHorizontal>
                  <SectionTitle>Settings</SectionTitle>
                </ComponentWrapper>
                <ComponentWrapper noPadding>
                  <Text>
                    <h3>Liquid Addresses</h3>
                  </Text>
                </ComponentWrapper>
              </Column>
            </Row>

            <Row>
              <Column part={9}>
                <ComponentWrapper noPadding>
                  <FlexCenterContainer
                    noPaddingHorizontal
                    style={{ padding: '16px' }}
                  ></FlexCenterContainer>
                  <Text>
                    <p>
                      From here you can manage all your Liquid addresses which
                      have been registered with STOKR.
                    </p>
                  </Text>
                </ComponentWrapper>
                <ComponentWrapper noPaddingHorizontal>
                  <Button
                    primary
                    onClick={
                      () => {
                        setRedirectCookie()

                        window.location.replace(
                          'https://signup.stokr.io/register-liquid-securities',
                        )
                      }
                      // onClick={() => navigate("/register-ethereum/flow")
                    }
                  >
                    add new address
                  </Button>
                </ComponentWrapper>
              </Column>
            </Row>
          </ComponentWrapper>

          <ComponentWrapper borderTop={addresses.length > 0}>
            {addresses.map((address, index) => (
              <Fragment key={address.id}>
                <CryptoAddress
                  title={index === 0 ? 'Liquid Addresses' : ''}
                  //address={address.address}
                  data={address.data}
                  info={address.info}
                  status={address.status}
                  //wrapped
                  // borderTop
                />
              </Fragment>
            ))}
          </ComponentWrapper>
        </SettingsLayout>
      }
    </>
  )
}

export default LiquidAddress
